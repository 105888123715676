import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IGetCompanyResponse, IGetCompanyStaffResponse, IGetCompanyTypeListResponse } from "app/business/interface/app/app-company.interface";
import { ApiCompany } from "../api/api-company.service";
@Injectable({
  providedIn: "root",
})
export class AppCompanyService {

  getCompany: BehaviorSubject<IGetCompanyResponse>;
  getCompanyStaff: BehaviorSubject<IGetCompanyStaffResponse>;
  getCompanyStaffDropdown: BehaviorSubject<IGetCompanyTypeListResponse>;


  constructor(private apiCompany: ApiCompany) {
    this.getCompany = new BehaviorSubject<IGetCompanyResponse>(null);
    this.getCompanyStaff = new BehaviorSubject<IGetCompanyStaffResponse>(null);

  }


  GetCompany(dataReq: any): Promise<IGetCompanyResponse> {
    return new Promise((resolve, reject) => {
      this.apiCompany.GetCompany(dataReq).subscribe((res) => {
        this.getCompany.next(res);
        resolve(res);
      });
    });
  }
  GetCompanyStaff(dataReq: any): Promise<IGetCompanyStaffResponse> {
    return new Promise((resolve, reject) => {
      this.apiCompany.GetCompanyStaff(dataReq).subscribe((res) => {
        this.getCompanyStaff.next(res);
        resolve(res);
      });
    });
  }
  getCompanyTypeList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCompany.getCompanyTypeList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getCompanyDetailStaffList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCompany.getCompanyDetailStaffList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getDocCompanyDetailStaff(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCompany.getDocCompanyDetailStaff(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getListCompanyByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompany.getListCompanyByFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }
  getCompanyProfileList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompany.getCompanyProfileList(request).subscribe(res => {
        resolve(res);
      });
    });
  }
  getCompanyProfile(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompany.getCompanyProfile(request).subscribe(res => {
        resolve(res);
      });
    });
  }
  getCompanyById(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompany.getCompanyById(request).subscribe(res => {
        resolve(res);
      });
    });
  }

  updateCompanyEPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompany.updateCompanyEPayment(request).subscribe(res => {
        resolve(res);
      });
    });
  }

}
