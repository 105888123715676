import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppDFTService } from 'app/business/service/app/app-dft.service';
import { AppReceiverService } from 'app/business/service/app/app-receiver.service';

const Mockup = [
  { pt_no: '02', tax_no: '0105523002096', name: 'บริษัท คอนโทรล ยูเนี่ยน (ประเทศไทย) จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ถั่วเขียว ถั่วเขียวผิวดำ ข้าวฟ่าง ปลาป่น ข้าวโพด แป้งมันสำปะหลัง', date_end: '2022-12-31', cpid: 1 },
  { pt_no: '03', tax_no: '0100515045151', name: 'บริษัท รับตรวจสินค้าโพ้นทะเล จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ถั่วเขียว ถั่วเขียวผิวดำ ข้าวฟ่าง ปลาป่น ข้าวโพด แป้งมันสำปะหลัง', date_end: '2022-12-31', cpid: 2 },
  { pt_no: '05', tax_no: '0105518007832', name: 'บริษัท ไทยแลนด์ อินเตอร์เนชั่นแนล อินสเปคชั่น จำกัด', product: 'ข้าวหอมมะลิ ปุยนุ่น ข้าวฟ่าง ข้าวโพด', date_end: '2022-12-31', cpid: 3 },
  { pt_no: '06', tax_no: '0105510001177', name: 'บริษัท ตรวจสอบสินค้าสากล จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ถั่วเขียว ข้าวฟ่าง ปลาป่น ข้าวโพด', date_end: '2022-12-31', cpid: 4 },
  { pt_no: '08', tax_no: '0105528006880', name: 'บริษัท อินเตอร์เทค เทสติ้ง เซอร์วิสเซส (ประเทศไทย) จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ข้าวโพด', date_end: '2022-12-31', cpid: 5 },
  { pt_no: '09', tax_no: '0105530015009', name: 'บริษัท ซี ซี ไอ ซี (ประเทศไทย) จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ข้าวโพด', date_end: '2022-12-31', cpid: 6 },
  { pt_no: '010', tax_no: '0105519014981', name: 'บริษัท เบล เซอร์เวย์ จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง', date_end: '2022-12-31', cpid: 7 },
  { pt_no: '011', tax_no: '0105532106079', name: 'บริษัท เอสจีเอส (ประเทศไทย) จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ถั่วเขียว ถั่วเขียวผิวดำ ข้าวฟ่าง ปลาป่น ข้าวโพด', date_end: '2022-12-31', cpid: 8 },
  { pt_no: '013', tax_no: '0105532057418', name: 'บริษัท บูโร เวอริทัส (ประเทศไทย) จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ข้าวโพด', date_end: '2022-12-31', cpid: 9 },
  { pt_no: '014', tax_no: '0105544011108', name: 'บริษัท ซี.พี.เอส. ตรวจสอบและรมยา จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง', date_end: '2022-12-31', cpid: 10 },
  { pt_no: '016', tax_no: '0105540074687', name: 'บริษัท อินเตอร์เนชั่นแนล เซอร์เวลแลนซ์ แอนด์ คอนซัลแตนซี จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2022-12-31', cpid: 11 },
  { pt_no: '018', tax_no: '0105545095798', name: 'บริษัท เค.พี.เอส.ตรวจสอบ จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ข้าวโพด', date_end: '2021-12-31', cpid: 12 },
  { pt_no: '021', tax_no: '0105545106188', name: 'บริษัท ปรีชนก อินสเป็คชั่น จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2021-12-31', cpid: 13 },
  { pt_no: '022', tax_no: '0103531024662', name: 'ห้างหุ้นส่วนจำกัด ไทยอบยาพืชไร่', product: 'ข้าวหอมมะลิ', date_end: '2021-12-31', cpid: 14 },
  { pt_no: '023', tax_no: '0105545116621', name: 'บริษัท ยูนิตี้ อะกริ อินสเป้คชั่น จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2022-12-31', cpid: 15 },
  { pt_no: '024', tax_no: '0105545115021', name: 'บริษัท ที.วี.วาย. ซัพพลายเออร์ จำกัด', product: 'ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง', date_end: '2022-12-31', cpid: 16 },
  { pt_no: '027', tax_no: '0105554149480', name: 'บริษัท เอเชีย เพสคอนโทรล แอนด์ อินสเปคชั่น จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2022-12-31', cpid: 17 },
  { pt_no: '028', tax_no: '0105537146206', name: 'บริษัท อามานะฮ์ซุปเปอร์รินเทนเด๊นส์คอร์ปอเรชั่น จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2019-12-31', cpid: 18 },
  { pt_no: '030', tax_no: '0105545131522', name: 'บริษัท มาดี เซอร์วิส จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2019-12-31', cpid: 19 },
  { pt_no: '033', tax_no: '0105546080409', name: 'บริษัท นีฎาวรรณ (ไทยแลนด์) จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2022-12-31', cpid: 20 },
  { pt_no: '035', tax_no: '0105548046518', name: 'บริษัท กลอบอล อินสเป้คชั่น จำกัด', product: 'ข้าวหอมมะลิ', date_end: '2021-12-31', cpid: 21 }
  // 36	105543086678	บริษัท ยูนิตี้ พ้อยท์ จำกัด	ข้าวหอมมะลิ 	2020-12-31
  // 40	115542001997	บริษัท เอสจีที เซอร์วิส (ประเทศไทย) จำกัด	ข้าวหอมมะลิ ข้าวโพด 	2022-12-31
  // 41	105548124306	บริษัท โคเทคนา อินสเปคชั่น (ประเทศไทย) จำกัด	ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง ข้าวโพด 	2022-12-31
  // 43	105552124692	บริษัท ควอลิตี้ คาร์โก้ อินสเปคชั่น จำกัด	ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง 	2021-12-31
  // 44	105543111281	บริษัท มาวิน อินสเปคชั่น จำกัด	ข้าวหอมมะลิ ผลิตภัณฑ์มันสำปะหลัง 	2022-12-31
]

@Component({
  selector: 'app-dft-surveyor-list',
  templateUrl: './dft-surveyor-list.component.html',
  styleUrls: ['./dft-surveyor-list.component.css']
})

export class DFTSurveyorListComponent implements OnInit {


  displayedColumns: string[] = [
    'ptNo',
    'taxNo',
    'name',
    'productId',
    'ms6DateEnd',
    'cpId'
  ];

  search: any = {};
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appDftservice: AppDFTService,
  ) {

  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  SortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  async loadData() {
    const data = {
      uid: this.userProfile.uId,
    }
    await this.appDftservice.getSurveyorList(
      data).then((res) => {
        this.dataSource.data = res

        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  clearOption() {
    this.search = {};
    this.searchData();
  }

  searchData() {
    const filterObj = {
      ptNo: (this.search.ptNo ? this.search.ptNo : '').toLowerCase(),
      name: (this.search.name ? this.search.name : '').toLowerCase(),
      taxNo: (this.search.taxNo ? this.search.taxNo : '').toLowerCase()
    };
    var defaulteFilterPredict = this.dataSource.filterPredicate;
    this.setFilterPredicate();
    this.dataSource.filter = JSON.stringify(filterObj);
    this.dataSource.filterPredicate = defaulteFilterPredict;
    this.search = {};
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filterObjectString: string) => {
      const filterObj = JSON.parse(filterObjectString);
      return (
        (data.ptNo ? data.ptNo : '').includes(filterObj.ptNo) &&
        (data.name ? data.name : '').toLowerCase().includes(filterObj.name) &&
        (data.taxNo ? data.taxNo : '').toLowerCase().includes(filterObj.taxNo)
      )
    }
  }
}