import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ApiCompany {
  constructor(private apiService: ApiService) {}

  GetCompany(request: any): Observable<any> {
    return this.apiService.post("/api/getcompany", request);
  }

  GetCompanyStaff(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyStaff", request);
  }

  getCompanyTypeList(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyTypeList", request);
  }
  getCompanyDetailStaffList(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyDetailStaffList", request);
  }
  getDocCompanyDetailStaff(request: any): Observable<any> {
    return this.apiService.post("/api/getDocCompanyDetailStaff", request);
  }
  getListCompanyByFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getListCompanyByFilter", {
      key: request,
    });
  }
  getCompanyProfileList(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyProfileList", request);
  }
  getCompanyById(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyById", request);
  }
  getCompanyProfile(request: number): Observable<any> {
    return this.apiService.post("/api/getCompanyProfile", {
      cpId: request,
    });
  }
  updateCompanyEPayment(request: number): Observable<any> {
    return this.apiService.post("/api/updateCompanyEPayment", request);
  }

  getReasonLogBlackList(request: number): Observable<any> {
    return this.apiService.post("/api/getReasonLogBlackList", request);
  }

  manualSyncSurveyorCompanyFromOcsConnectAsync(): Observable<any> {
    return this.apiService.post("/api/manualSyncSurveyorCompanyFromOcsConnectAsync", {});
  }
}
